<template>
  <div class="index-page">
    <HeaderComponent :showSearch="false" />
    <div class="index-panel">
      <h1>个人互联网检索服务</h1>
      <template v-if="!isLogin">
        <p>该服务仅提供给内部用户，请先登录。</p>
        <div class="index-panel-btn">
          <el-button type="primary" @click="showLoginDialog"
            >立即登录</el-button
          >
        </div>
      </template>
      <template v-else>
        <div class="form-wrap">
          <div class="search-form">
            <input
              autocomplete="off"
              type="text"
              placeholder="请输入你要搜索的关键词"
              class="input"
              @click.stop=""
              @keyup.enter="submit"
              v-model="keyword"
              autofocus
            />
            <span class="search-button" @click="submit" />
          </div>
          <p style="color: #666">
            *爬虫服务只能作为版权监测服务和个人学习用途，如再次分享，将追究你个人的法律责任
          </p>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import HeaderComponent from "../components/Header.vue";

export default {
  components: {
    HeaderComponent,
  },
  data() {
    return {
      keyword: "",
      isFrame: false,
    };
  },
  mounted() {
    if (window.self != window.top) {
      this.isFrame = true;
    }
    setTimeout(() => {
      if (this.$route.query.keyword) this.keyword = this.$route.query.keyword;
    }, 10);
  },
  methods: {
    submit() {
      if (this.isFrame) {
        return this.openWindow(
          `${window.location.origin}/#/main/search?keyword=${encodeURIComponent(
            this.keyword
          )}`,
          true
        );
      }
      this.$store.commit("changeFilterSearchType", -1);
      return (window.location.href = `${
        window.location.origin
      }/#/main/search?keyword=${encodeURIComponent(this.keyword)}`);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.index-page {
  .index-panel {
    padding-top: 200px;
    text-align: center;
    margin-bottom: 100px;
    min-height: 400px;
    h1 {
      font-size: 40px;
      margin-bottom: 10px;
    }
    .el-button {
      margin-top: 30px;
      width: 160px;
      font-size: 18px;
      height: 55px;
      border-radius: 8px;
    }
  }
  .form-wrap {
    .search-form {
      position: relative;
      max-width: 90%;
      display: inline-block;
      margin-top: 20px;
      margin-bottom: 20px;

      .input {
        width: 600px;
        max-width: 100%;
        font-size: 15px;
        border-radius: 6px;
        height: 50px;
        padding-left: 10px;
        padding-right: 60px;
        outline: none;
        border: 1px solid #ddd;
        background-color: #f8f8f8;
        transition: all ease 0.3s;
        &:focus {
          border-color: $theme-color;
        }
      }
      .search-button {
        display: inline-block;
        position: absolute;
        bottom: 5px;
        width: 28px;
        right: 10px;
        top: 5px;
        z-index: 2;
        outline: none;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        transition: all ease 0.2s;
        background: url("../assets/images/search.png") no-repeat center center;
        background-size: 20px 20px;
      }
    }
  }
}
</style>
